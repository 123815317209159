import { FC } from 'react';

import { IconType } from 'react-icons';

import Link from 'next/link';

import { Container } from './styles';

interface IAuthLinkProps {
  icon: IconType;
  title: string;
  href: string;
}

export const AuthLink: FC<IAuthLinkProps> = ({ href, icon: Icon, title }) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <Container>
        <Icon />

        <span>{title}</span>
      </Container>
    </Link>
  );
};
