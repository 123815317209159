import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { InferGetServerSidePropsType, NextPage } from 'next';

import { useToast } from '~/shared/hooks/useToast';

import { SignIn } from '~/modules/auth/pages/SignIn';

import { withAuthentication } from '~/shared/utils/withAuthentication';

export const getServerSideProps = withAuthentication(async (ctx) => {
  const { toastData } = ctx.query;

  return { props: { toastData: (toastData as string) || '{}' } };
});

const SignInPage: NextPage<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = ({ toastData }) => {
  const toast = useToast();
  const router = useRouter();

  useEffect(() => {
    const parsedToastData = JSON.parse(toastData);

    if (Object.values(parsedToastData).length > 0) {
      toast.show(parsedToastData);

      router.push({ pathname: router.pathname }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastData]);

  return <SignIn />;
};

export default SignInPage;
