import { FC } from 'react';

import Image from 'next/image';

import seruLogoImg from '~/shared/assets/images/seru-logo-with-text.png';

import { Container, LogoWrapper } from './styles';

interface IAuthHeaderProps {
  title: string;
  subtitle: string;
  customLogo?: string;
}

export const AuthHeader: FC<IAuthHeaderProps> = ({
  title,
  subtitle,
  customLogo,
}) => {
  return (
    <>
      <LogoWrapper>
        <Image
          src={customLogo || seruLogoImg}
          alt="SERU"
          width={280}
          height={100}
          objectFit="contain"
          objectPosition="center"
          priority
          quality={100}
        />
      </LogoWrapper>

      <Container>
        <h1>{title}</h1>

        <p>{subtitle}</p>
      </Container>
    </>
  );
};
